import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import {fetchCities} from "../../services/apiService.ts";
import {City} from "../../types/City.tsx";

export const loadCities = createAsyncThunk('cities/loadCities', async () => {
    return await fetchCities();
});

interface CityState {
    items: City[];
    currentCity: Partial<City | null>;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const loadCitiesFromLocalStorage = (): CityState => {
    try {
        const cartData = localStorage.getItem('cities');
        if (cartData) {
            return JSON.parse(cartData);
        }
    } catch (error) {
        console.error('Error loading cities from localStorage:', error);
    }
    return {error: null, status: 'idle', items: [], currentCity: null };
};

const initialState: CityState = loadCitiesFromLocalStorage();

const citySlice = createSlice({
    name: 'cities',
    initialState,
    reducers: {
        setCurrentCity(state, action: PayloadAction<City | null>) {
            state.currentCity = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadCities.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadCities.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // @ts-ignore
                state.items = action.payload as City[];
                if (!state.currentCity ||
                    (typeof state.currentCity === 'object' && Object.keys(state.currentCity).length === 0)
                ) {
                    state.currentCity = action.payload[0] ?? undefined;
                }
            })
            // @ts-ignore
            .addCase(loadCities.rejected, (state, action) => {
                state.status = 'failed';
            })
    },
});

export const { setCurrentCity } = citySlice.actions;

export default citySlice.reducer;