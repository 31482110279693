import {FC, useState} from "react";
import {Formik} from "formik";
import {Button, Logo} from "../../components/common";
import {AuthLayout} from "../../layouts";
import {useAuth} from "../../providers/AuthProvider.tsx";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";

const RegistrationPage: FC = () => {
    const { register } = useAuth()
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [, setIsLoading] = useState(false)
    // @ts-ignore
    const {telephone, email} = JSON.parse(window.settings);

    const RegistrationSchema = Yup.object().shape({
        email: Yup.string().email(t('error.validation.invalid_email')).required(t('error.validation.required')),
        password: Yup.string()
            .required(t('error.validation.required'))
            .min(8, t('error.validation.password_too_short'))
            .matches(/[a-zA-Z]/, t('error.validation.password_latin')),
        company_name: Yup.string().required(t('error.validation.required')),
        tin: Yup.string().required(t('error.validation.required')),
        invoice_address: Yup.string().required(t('error.validation.required')),
        phone: Yup.string().required(t('error.validation.required')),
    });

    return <AuthLayout>
        <div className="flex flex-col items-center justify-center h-screen">
            <Formik
                initialValues={{ email: '', password: '', company_name: '', tin: '', invoice_address: '', phone: '' }}
                onSubmit={(values) => {
                    (async () => {
                        setIsLoading(true)
                        register(values)
                            .then(() => navigate('/auth/need_confirmation'))
                            .finally(() => {setIsLoading(true)})
                    })()
                }}
                validationSchema={RegistrationSchema}
            >
                {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      errors,
                      touched
                  }) => (
                    <div className="flex flex-col w-full max-w-96 gap-y-4">
                        <div className="m-auto">
                            <Logo/>
                        </div>

                        <div className="flex flex-col gap-y-1">
                            <div>
                                <label htmlFor="email"
                                       className="block text-sm font-medium leading-6">{t('label.email')}</label>
                            </div>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                autoComplete="email"
                                required
                                placeholder="example@company.com"
                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-4"/>
                            {errors.email && touched.email ? (
                                <div className="text-red text-sm">{errors.email}</div>) : null}
                        </div>

                        <div className="flex flex-col gap-y-1">
                            <div>
                                <label htmlFor="phone"
                                       className="block text-sm font-medium leading-6">{t('label.phone')}</label>
                            </div>
                            <input
                                id="phone"
                                name="phone"
                                type="text"
                                value={values.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                placeholder={t('label.phone')}
                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-4"/>
                            {errors.phone && touched.phone ? (
                                <div className="text-red text-sm">{errors.phone}</div>) : null}
                        </div>

                        <div className="flex flex-col gap-y-1">
                            <div>
                                <label
                                    htmlFor="password"
                                    className="block text-sm font-medium leading-6 "
                                >{t('label.password')}</label>
                            </div>

                            <input
                                id="password"
                                name="password"
                                type="password"
                                value={values.password}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                autoComplete="current-password"
                                placeholder="********"
                                required
                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-4"/>
                            {errors.password && touched.password ? (
                                <div className="text-red text-sm">{errors.password}</div>) : null}
                        </div>

                        <div className="flex flex-col gap-y-1">
                            <div>
                                <label htmlFor="company_name"
                                       className="block text-sm font-medium leading-6">{t('label.company_name')}</label>
                            </div>
                            <input
                                id="company_name"
                                name="company_name"
                                type="company_name"
                                value={values.company_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                autoComplete="company_name"
                                required
                                placeholder={t('label.company_name')}
                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-4"/>
                            {errors.company_name && touched.company_name ? (
                                <div className="text-red text-sm">{errors.company_name}</div>) : null}
                        </div>

                        <div className="flex flex-col gap-y-1">
                            <div>
                                <label htmlFor="tin"
                                       className="block text-sm font-medium leading-6">{t('label.tin')}</label>
                            </div>
                            <input
                                id="tin"
                                name="tin"
                                type="tin"
                                value={values.tin}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                autoComplete="tin"
                                required
                                placeholder={t('label.tin')}
                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-4"/>
                            {errors.tin && touched.tin ? (
                                <div className="text-red text-sm">{errors.tin}</div>) : null}
                        </div>

                        <div className="flex flex-col gap-y-1">
                            <div>
                                <label htmlFor="invoice_address"
                                       className="block text-sm font-medium leading-6">{t('label.invoice_address')}</label>
                            </div>
                            <input
                                id="invoice_address"
                                name="invoice_address"
                                type="text"
                                value={values.invoice_address}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                placeholder={t('label.invoice_address')}
                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-4"/>
                            {errors.invoice_address && touched.invoice_address ? (
                                <div className="text-red text-sm">{errors.invoice_address}</div>) : null}
                        </div>

                        <div>
                            <Button title={t('button.sign_up')} onClick={handleSubmit}/>
                        </div>

                        <div className="text-center">
                            <p>{t('title.already_have_account')} <a
                                href=""
                                onClick={() => navigate("/login")}
                                className="text-primary cursor-pointer"
                            >{t('title.login_in')}!</a></p>
                        </div>

                        <div className="flex flex-row gap-x-4">
                            <div className="w-1/2">
                                <p>{t('label.need_help')}</p>
                            </div>
                            <div className="w-1/2 flex flex-col items-end">
                                <a href={`tel:${telephone}`}>{telephone}</a>
                                <a href={`mail:${email}`}>{email}</a>
                            </div>
                        </div>
                    </div>
                )}
            </Formik>
        </div>
    </AuthLayout>
}

export default RegistrationPage