import {FC, useEffect, useState} from "react";
import {Formik} from "formik";
import {AuthLayout} from "../../layouts";
import {useAuth} from "../../providers/AuthProvider.tsx";
import {useTranslation} from "react-i18next";
import {Button, Logo} from "../../components/common";
import * as Yup from 'yup';
import {useNavigate} from "react-router-dom";
import {loadCities} from "../../redux/slices/citySlice.ts";
import {loadPaymentMethods} from "../../redux/slices/paymentMethodsSlice.ts";
import {useAppDispatch} from "../../hooks/redux.ts";

const LoginPage: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const {login} = useAuth()
    const { t } = useTranslation();
    const [, setIsLoading] = useState(false)
    // @ts-ignore
    const {telephone, email} = JSON.parse(window.settings);

    useEffect(() => {
        dispatch(loadCities());
        dispatch(loadPaymentMethods());
    }, [dispatch]);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email(t('error.validation.invalid_email')).required(t('error.validation.required')),
        password: Yup.string()
            .required(t('error.validation.required'))
            .min(8, t('error.validation.password_too_short'))
            .matches(/[a-zA-Z]/, t('error.validation.password_latin'))
    });

    return <AuthLayout>
        <div className="flex flex-col items-center justify-center h-screen">
            <Formik
                initialValues={{ email: '', password: '' }}
                onSubmit={(values) => {
                    (async () => {
                        setIsLoading(true)
                        login(values)
                            .finally(() => {setIsLoading(true)})
                    })()
                }}
                validationSchema={LoginSchema}
            >
                {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      errors,
                      touched
                  }) => (
                    <div className="flex flex-col w-full max-w-96 gap-y-4">
                        <div className="m-auto">
                            <Logo />
                        </div>

                        <div className="flex flex-col gap-y-1">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6">{t('label.email')}</label>
                            </div>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                autoComplete="email"
                                required
                                placeholder="example@company.com"
                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-4"/>
                            {errors.email && touched.email ? (<div className="text-red text-sm">{errors.email}</div>) : null}
                        </div>

                        <div className="flex flex-col gap-y-1">
                            <div>
                                <label
                                    htmlFor="password"
                                    className="block text-sm font-medium leading-6 "
                                >{t('label.password')}</label>
                            </div>

                            <input
                                id="password"
                                name="password"
                                type="password"
                                value={values.password}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                autoComplete="current-password"
                                placeholder="********"
                                required
                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-4"/>
                                {errors.password && touched.password ? (<div className="text-red text-sm">{errors.password}</div>) : null}
                        </div>

                        <div>
                            <Button title={t('button.login')} onClick={handleSubmit} />
                        </div>

                        <div className="text-center">
                            <p>{t('title.dont_have_account')} <a
                                href=""
                                onClick={() => navigate("/registration")}
                                className="text-primary cursor-pointer"
                            >{t('title.sign_up_now')}!</a></p>
                        </div>

                        <div className="flex flex-row gap-x-4">
                            <div className="w-1/2">
                                <p>{t('title.need_help')}</p>
                            </div>
                            <div className="w-1/2 flex flex-col items-end">
                                <a href={`tel:${telephone}`}>{telephone}</a>
                                <a href={`mail:${email}`}>{email}</a>
                            </div>
                        </div>
                    </div>
                )}
            </Formik>
        </div>
    </AuthLayout>
}

export default LoginPage