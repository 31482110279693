import {FC} from "react";

interface ButtonProps {
    title: string,
    onClick?: () => void,
    disabled?: boolean
}

const Button: FC<ButtonProps> = ({title, onClick, disabled}) => {
    return <button
        disabled={disabled}
        onClick={onClick}
        className="w-full py-2 text-center bg-primary text-white rounded-full disabled:bg-gray-200 disabled:cursor-not-allowed"
    >{title}</button>
}

export default Button