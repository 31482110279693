import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {PaymentMethod} from "../../types/PaymentMethod.ts";
import {fetchPaymentMethods} from "../../services/apiService.ts";

interface PaymentMethodsState {
    methods: PaymentMethod[];
    status: "idle" | "loading" | "succeeded" | "failed";
    error: string | null;
}

const initialState: PaymentMethodsState = {
    methods: [],
    status: "idle",
    error: null,
};

export const loadPaymentMethods = createAsyncThunk('paymentMethods/fetchPaymentMethods', async () => {
    const response = await fetchPaymentMethods();
    return response as PaymentMethod[];
});

const paymentMethodsSlice = createSlice({
    name: "paymentMethods",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadPaymentMethods.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(loadPaymentMethods.fulfilled, (state, action: PayloadAction<PaymentMethod[]>) => {
                state.status = "succeeded";
                state.methods = action.payload;
                state.error = null;
            })
            .addCase(loadPaymentMethods.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    },
});

export default paymentMethodsSlice.reducer;
