import {FC, ReactElement} from "react";
import {ToastContainer} from "react-toastify";

const AuthLayout: FC<{children: ReactElement}> = ({children}) => {
    return <div>
        {children}

        <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
        />
    </div>
}

export default AuthLayout