import React, {useEffect, useMemo, useRef, useState} from 'react';
import { useTable, useSortBy, useRowSelect } from 'react-table';
import { Product } from '../../../types/Catalog';
import {useAppDispatch, useAppSelector} from "../../../hooks/redux.ts";
import {addItem, removeItem} from "../../../redux/slices/cartSlice.ts";
import {currency} from "../../../constants/defaultValues.ts";
import {useTranslation} from "react-i18next";
import Modal from 'react-modal';
import {AiOutlineCloseCircle} from "react-icons/ai";
import { FaChevronDown } from "react-icons/fa";
import i18n from "../../../i18n";

interface CatalogTableProps {
    data: Product[];
}

const CatalogTable: React.FC<CatalogTableProps> = ({ data }) => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const [product, setProduct] = useState<Product | null>(null)
    const [productModalOpen, setProductModalOpen] = useState(false);
    const cartItems = useAppSelector((state) => state.cart.items);
    const [quantities, setQuantities] = useState<{ [id: number]: number }>({});
    const quantitiesRef = useRef<{ [id: number]: number }>({});
    const [features, setFeatures] = useState<{ [id: string]: string }>({});
    const [featuresOpen, setFeaturesOpen] = useState(false);

    const handleQuantityChange = (id: number, quantity: number, product: Product) => {
        const parsedQuantity = Math.max(0, +quantity);
        quantitiesRef.current[id] = parsedQuantity;

        if (parsedQuantity === 0) {
            dispatch(removeItem(id));
        } else {
            dispatch(addItem({ ...product, quantity: parsedQuantity }));
        }

        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [id]: parsedQuantity,
        }));
    };


    useEffect(() => {
        setFeatures(product?.features ?? {})
    }, [product?.id]);

    useEffect(() => {
        const initialQuantities: { [id: number]: number } = {};
        cartItems.forEach(item => {
            initialQuantities[item.id] = item.quantity;
            quantitiesRef.current[item.id] = item.quantity;
        });

        setQuantities(initialQuantities);
    }, [cartItems]);

    const columns = useMemo(
        () => [
            // {
            //     Header: t('field.sku'),
            //     accessor: 'sku',
            // },
            {
                Header: t('field.image'),
                accessor: 'image',
                Cell: ({ row }: { row: any }) => row?.values?.image ? <div className="m-auto flex flex-row items-center justify-center w-full cursor-pointer py-1" onClick={() => {
                    setProductModalOpen(true)
                    setProduct(row.original)
                }}>
                    <img src={`${import.meta.env.VITE_IMAGES_URL}${row.values.image}`} alt="" width={50} height={50} style={{maxHeight: 50, width: "auto"}} />
                </div> : null,
            },
            {
                Header: t('field.name'),
                accessor: 'name',
                Cell: ({ row }: { row: any }) => {
                    return <p className="cursor-pointer" onClick={() => {
                        setProductModalOpen(true)
                        setProduct(row.original)
                    }}>{row.values.name}</p>
                },
            },
            // {
            //     Header: t('field.package'),
            //     accessor: 'package',
            // },
            // {
            //     Header: t('field.country'),
            //     accessor: 'country',
            // },
            {
                Header: t('field.weight'),
                accessor: 'weight',
                Cell: ({ value }: { value: number }) => value < 1 ? `${value * 1000} g` : `${value} kg`,
            },
            {
                Header: t('field.net'),
                accessor: 'net_price',
                Cell: ({ value }: { value: number }) => `${value} ${currency}`,
            },
            {
                Header: t('field.vat'),
                accessor: 'tax_rate',
                Cell: ({ value }: { value: number }) => `${value} %`,
            },
            {
                Header: t('field.gross'),
                accessor: 'final_price',
                Cell: ({ value }: { value: number }) => `${value.toFixed(2)} ${currency}`,
            },
            {
                Header: t('field.qty'),
                accessor: 'quantity',
                Cell: ({ row }: any) => {
                    return <input
                            type="number"
                            min="1"
                            value={quantitiesRef.current[row.original.id] || 0}
                            onChange={(e) => {
                                handleQuantityChange(
                                    row.original.id,
                                    Number(e.target.value),
                                    row.original
                                )
                            }}
                            className="w-16 p-1 border border-gray-300 rounded text-center"
                        />
                },
            },
            {
                accessor: 'unit',
            },
        ],
        [i18n.language]
    );

    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
        useTable(
            {
                // @ts-ignore
                columns,
                data,
            },
            useSortBy,
            useRowSelect
        );

    const handleClose = () => {
        setProductModalOpen(false);
        setProduct(null);
    }

    return (
        <div className="p-2 h-full overflow-y-auto no-scrollbar text-sm">
            <table {...getTableProps()} className="min-w-full border-separate border-spacing-y-2">
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th
                                { // @ts-ignore
                                ...column.getHeaderProps(column.getSortByToggleProps())}
                                className="cursor-pointer text-left font-semibold p-4"
                            >
                                {column.render('Header')}
                                <span>
                                        { // @ts-ignore
                                            column.isSorted
                                                // @ts-ignore
                                            ? column.isSortedDesc
                                                ? ' ↓'
                                                : ' ↑'
                                            : ''}
                                    </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} className="shadow-md p-4 rounded-2xl items-center bg-white">
                                {row.cells.map((cell, index) => {
                                    return <td
                                        {...cell.getCellProps()}
                                        className={`${cell.column.id != 'image' && 'p-4'} ${index+1 === 1 ? 'rounded-l-2xl' : ''} ${index+1 === row.cells.length ? 'rounded-r-2xl' : ''}`}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <Modal
                isOpen={productModalOpen}
                onAfterOpen={() => setProductModalOpen(true)}
                onRequestClose={handleClose}
            >
                <div className="flex flex-row items-end justify-end pb-2">
                    <div className="cursor-pointer">
                        <AiOutlineCloseCircle size={20} onClick={handleClose}/>
                    </div>
                </div>
                <div className="flex flex-row p-4 h-full">
                    <div className="w-1/2">
                        <div className="flex flex-row justify-center items-center gap-x-2">
                            {
                                product?.image &&
                                <img src={`${import.meta.env.VITE_IMAGES_URL}${product!.image}`} alt=""
                                     className="w-auto h-full max-h-72"/>
                            }
                        </div>
                    </div>
                    <div className="w-1/2">
                        {
                            product && <div className="flex flex-col gap-x-2 gap-y-2 h-full overflow-y-auto">
                                <p className="font-bold text-2xl">{product?.name}</p>
                                <p className="font-bold text-xl">{product?.final_price} {currency}</p>
                                <input
                                    type="number"
                                    value={quantities[product.id] || 0}
                                    onChange={(e) => {
                                        handleQuantityChange(
                                            product.id,
                                            Number(e.target.value),
                                            product
                                        )
                                    }}
                                    className="w-16 p-1 border border-gray-300 rounded text-center"
                                />

                                <p className="text-base">{product?.description}</p>

                                {product?.package &&
                                    <p className="text-sm"><b>{t('field.package')}</b>: {product?.package}</p>}
                                {product?.composition &&
                                    <p className="text-sm"><b>{t('field.composition')}</b>: {product?.composition}</p>}
                                {product?.weight &&
                                    <p className="text-sm">
                                        <b>{t('field.weight')}</b>: {(product?.weight && product?.weight < 1) ? `${product.weight * 1000} g` : `${product.weight} kg`}
                                    </p>}
                                {
                                    Object.keys(features).length > 0 && <div onClick={() => setFeaturesOpen(!featuresOpen)}>
                                        <p className="text-sm flex flex-row items-center gap-x-2 cursor-pointer"><b>{t('field.features')}</b> <FaChevronDown
                                            className={`transform transition-transform duration-300 ${
                                                featuresOpen ? "rotate-180" : "rotate-0"
                                            }`}
                                        /></p>
                                        {featuresOpen && (
                                            <div className="mt-4 p-4 bg-gray-100 border rounded">
                                                {
                                                    Object.keys(features).map(featureKey => {
                                                        const feature = features[featureKey];
                                                        return <p className="text-sm"><b>{featureKey}</b>: {feature}</p>
                                                    })
                                                }
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default CatalogTable;