import {useAppDispatch, useAppSelector} from "../../hooks/redux.ts";
import {useEffect} from "react";
import {loadCategories} from "../../redux/slices/categoriesSlice.ts";
import {Logo} from "../../components/common";
import {useCatalog} from "../../contexts/CatalogContext.tsx";
import {useLocation, useNavigate} from "react-router-dom";
import {IoCallOutline} from "react-icons/io5";

const CatalogSidebar = () => {
    const { selectedCategory, setSelectedCategory } = useCatalog();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const categories = useAppSelector((state) => state.categories.items);
    const categoriesStatus = useAppSelector((state) => state.categories.status);
    // @ts-ignore
    const telephone = JSON.parse(window.settings).telephone;
    useEffect(() => {
        if (categoriesStatus === 'idle') {
            dispatch(loadCategories());
        }
    }, [categoriesStatus, dispatch]);

    const handleCategoryClick = (categoryId: number) => {
        setSelectedCategory(categoryId)
        if (location.pathname != '/') navigate(`/`);
    }

    return <div className="flex flex-col shadow-md h-full p-2 rounded-br-2xl bg-white justify-between overflow-y-auto">
        <div className="flex flex-col w-full">
            <div className="p-4">
                <Logo/>
            </div>
            <div className="flex flex-col w-full justify-center overscroll-y-auto divide-y divide-gray-200 h-fit">
                {categories.map((category) => (
                    <div key={category.id}
                         className={`flex flex-row w-full items-center px-2 py-2 hover:bg-primary hover:text-white cursor-pointer rounded-2xl ${selectedCategory == category.id && 'bg-primary text-white'}`}
                         onClick={() => handleCategoryClick(category.id)}
                    >
                        <h2>{category.name}</h2>
                        <p>{category.description}</p>
                    </div>
                ))}
            </div>
        </div>

        <div className="px-4 py-2 rounded-lg border border-gray-300">
            <a href={`tel:${telephone}`} className="flex flex-row items-center gap-x-2">
                <IoCallOutline size={16}/> {telephone}</a>
        </div>
    </div>
}

export default CatalogSidebar