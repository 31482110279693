export default {
    "label": {
        "email": "Email",
        "password": "Password",
        "need_help": "Need help? Contact us by phone or email.",
        "cart_total": "Cart total",
        "back_to_cart": "Back to cart",
        "choose_address": "Choose address",
        "choose_payment_method": "Choose payment method",
        "cart_is_empty": "Your cart is empty",
        "tin": "TIN/NIP",
        "company_name": "Company name",
        "delivery_date": "Delivery date",
        "manager": "Manager",
        "order": "Order",
        "not_found": "No product found",
        "total": "Total",
        "created_at": "Created at",
        "no_addresses": "No saved addresses",
        "comment": "Comment",
        "invoice_address": "Invoice address",
        "phone": "Telephone",
        "search_product": "Search product",
        "delivery_price": "Delivery price",
    },
    "button": {
        "login": "Login",
        "checkout": "Checkout",
        "place_order": "Place order",
        "sign_up": "Sign up",
        "catalog": "Catalog",
        "change_password": "Change password",
        "company_information": "Company information",
        "addresses": "Addresses",
        "add_new_address": "Add new address",
        "save": "Save"
    },
    "title": {
        "dont_have_account": "Don't have an account?",
        "sign_up_now": "Sign up now",
        "need_help": "Need help? Contact us by phone or email.",
        "already_have_account": "Already have an account?",
        "login_in": "Login in",
        "thank_you_for_order": "Thank you for your order!",
        "order_id_is": "Your order ID is",
        "you_will_receive_invoice": "You will receive an invoice at your registered email shortly.",
        "no_orders_yet": "You don't have any orders yet",
        "free_delivery_sum": "Free delivery is available for orders over {{sum}} {{currency}}."
    },
    "error": {
        "validation": {
            "invalid_email": "Invalid email address",
            "invalid_password": "Invalid password",
            "required": "Required",
            "password_too_short": "Password is too short - should be 8 chars minimum.",
            "password_latin": "Password can only contain Latin letters",
            "select_delivery_date": "Select delivery date",
            "select_address": "Select address",
            "select_payment_method": "Select payment method",
            "passwords_do_not_match": "Password do not match",
            "city_required": "City is required",
            "street_required": "Street is required",
            "street_255": "Street must be at most 255 characters",
            "state_255": "State must be at most 255 characters",
            "postal_required": "Postal code is required",
            "postal_255": "Postal code must be at most 20 characters",
            "country_required": "Country is required",
            "country_255": "Country must be at most 100 characters",
            "no_address_available": "No addresses available for selection. Please add an address or check the selected city."
        },
        "message": {
            "incorrect_current_password": "Incorrect current password",
            "something_went_wrong": "Unfortunately, something went wrong. Please try again later or contact us by phone or email",
            "no_user_found": "No active account found with the given credentials. Please confirm your email to activate your account."
        }
    },
    "field": {
        "product": "Product",
        "price": "Price",
        "total": "Total",
        "qty": "Quantity",
        "name": "Name",
        "image": "Image",
        "package": "Package",
        "weight": "Weight",
        "country": "Country",
        "repeat_password": "Repeat password",
        "current_password": "Current password",
        "new_password": "New Password",
        "street": "Street",
        "postal_code": "Postal Code",
        "composition": "Composition",
        "features": "Features",
        "sku": "Sku",
        "net": "Net",
        "gross": "Gross",
        "vat": "Tax",
        "unit": "Unit"
    },
    "header": {
        "account": "Account",
        "logout": "Logout",
        "orders": "Orders"
    },
    "message": {
        "password_updated": "Password updated",
        "updated": "Updated",
        "saved": "Saved",
        "email_confirmed": "Your email has been successfully confirmed! You can now access and use the portal. Thank you for choosing our service!",
        "confirm_email": "Please confirm your email to start using the portal"
    }
}