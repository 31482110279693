import React, { createContext, useContext, useState } from 'react';

interface CatalogContextProps {
    selectedCategory: number | null;
    setSelectedCategory: (categoryId: number | null) => void;
}

const CatalogContext = createContext<CatalogContextProps | undefined>(undefined);

export const CatalogProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [selectedCategory, setSelectedCategory] = useState<number | null>(null);

    return (
        <CatalogContext.Provider value={{ selectedCategory, setSelectedCategory }}>
            {children}
        </CatalogContext.Provider>
    );
};

export const useCatalog = (): CatalogContextProps => {
    const context = useContext(CatalogContext);
    if (!context) {
        throw new Error('useCatalog must be used within a CatalogProvider');
    }
    return context;
};