import {FC, useEffect, useState} from "react";
import {StoreLayout} from "../../layouts";
import {useCatalog} from "../../contexts/CatalogContext.tsx";
import {useAppDispatch, useAppSelector} from "../../hooks/redux.ts";
import {loadCategories} from "../../redux/slices/categoriesSlice.ts";
import {filterProducts, loadProducts} from "../../redux/slices/productsSlice.ts";
import {Product} from "../../types/Catalog.ts";
import CatalogTable from "./components/ProductsTable.tsx";
import {loadPaymentMethods} from "../../redux/slices/paymentMethodsSlice.ts";
import {loadCities} from "../../redux/slices/citySlice.ts";

type SortOrder = 'asc' | 'desc';

const HomePage: FC = () => {
    const dispatch = useAppDispatch();
    const products = useAppSelector((state) => state.products.filtered);
    const { selectedCategory } = useCatalog();
    const [sortColumn, ] = useState<keyof Product | null>('id');
    const [sortOrder, ] = useState<SortOrder>('asc');

    useEffect(() => {
        dispatch(loadCities());
        dispatch(loadPaymentMethods());
    }, [dispatch]);

    useEffect(() => {
        dispatch(loadCategories());
        if (selectedCategory) {
            dispatch(filterProducts({ query: '', categoryIds: [selectedCategory] }));
        } else {
            dispatch(loadProducts());
        }
    }, [selectedCategory, dispatch]);

    const sortedProducts = [...products].sort((a, b) => {
        if (!sortColumn) return 0;
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (typeof aValue === 'string' && typeof bValue === 'string') {
            return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }

        if (typeof aValue === 'number' && typeof bValue === 'number') {
            return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
        }

        return 0;
    });

    return <StoreLayout>
        <CatalogTable data={sortedProducts}/>
    </StoreLayout>
}

export default HomePage