import {AuthLayout} from "../../layouts";
import {Button, Logo} from "../../components/common";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const EmailNeedConfirmation = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()

    return <AuthLayout>
        <div className="flex flex-col items-center justify-center h-screen">
            <div className="flex flex-col w-full max-w-96 gap-y-4 text-center">
                <div className="m-auto">
                    <Logo/>
                </div>
                <p>
                    {t('message.confirm_email')}
                </p>
                <div>
                    <Button title={t('button.login')} onClick={() => navigate('/login')}/>
                </div>
            </div>
        </div>
    </AuthLayout>
}

export default EmailNeedConfirmation