import { configureStore } from '@reduxjs/toolkit'
import productsReducer from './slices/productsSlice';
import categoriesReducer from './slices/categoriesSlice';
import cartReducer from './slices/cartSlice';
import paymentMethodsReducer from "./slices/paymentMethodsSlice.ts";
import cityReducer from "./slices/citySlice.ts";

const saveToLocalStorage = (state: RootState) => {
    try {
        const cartData = JSON.stringify(state.cart);
        localStorage.setItem('cart', cartData);
        const citiesData = JSON.stringify(state.cities);
        localStorage.setItem('cities', citiesData);
    } catch (error) {
        console.error('Error saving store to localStorage:', error);
    }
};

export const store = configureStore({
    reducer: {
        products: productsReducer,
        categories: categoriesReducer,
        cart: cartReducer,
        paymentMethods: paymentMethodsReducer,
        cities: cityReducer,
    },
})

store.subscribe(() => {
    saveToLocalStorage(store.getState());
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch