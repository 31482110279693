import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ProductsState, Product } from '../../types/Catalog.ts';
import {fetchProducts} from '../../services/apiService.ts';

const initialState: ProductsState = {
    items: [],
    filtered: [],
    status: 'idle',
    error: null,
};

export const loadProducts = createAsyncThunk('products/loadProducts', async () => {
    const response = await fetchProducts();
    return response as Product[];
});

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        filterProducts(state, action: PayloadAction<{ query: string; categoryIds?: number[] }>) {
            const { query, categoryIds = [] } = action.payload;
            const lowerCaseQuery = query.toLowerCase();

            state.filtered = state.items.filter((product) => {
                const matchesName = product.name.toLowerCase().includes(lowerCaseQuery);
                const matchesDescription = product.description.toLowerCase().includes(lowerCaseQuery);
                const matchesCategory =
                    categoryIds.length === 0 || product.categories.some((category) => categoryIds.includes(category.id));

                return (matchesName || matchesDescription) && matchesCategory;
            });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadProducts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadProducts.fulfilled, (state, action: PayloadAction<Product[]>) => {
                state.status = 'succeeded';
                state.items = action.payload;
                state.filtered = action.payload;
            })
            .addCase(loadProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || null;
            })
    },
});

export const { filterProducts } = productsSlice.actions;

export default productsSlice.reducer;