import {StoreLayout} from "../../layouts";
import {useEffect, useState} from "react";
import {Order} from "../../types/Order.ts";
import {fetchOrders} from "../../services/apiService.ts";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../hooks/redux.ts";
import {loadProducts} from "../../redux/slices/productsSlice.ts";
import {currency} from "../../constants/defaultValues.ts";
import {Button} from "../../components/common";
import {useNavigate} from "react-router-dom";

const OrdersPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const products = useAppSelector(state => state.products.items)
    const {t} = useTranslation();
    const [orders, setOrders] = useState<Order[]>([]);

    useEffect(() => {
        dispatch(loadProducts())
        fetchOrders().then(data => {
            console.log(data)
            setOrders(data);
        })
    }, []);

    // @ts-ignore
    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const getProductName = (productId: number) => {
        console.log(products)
        const product = products.find((p) => p.id === productId);
        if (product) {
            return product.name
        }

        return t('label.not_found')
    }

    return <StoreLayout>
        {
            !orders.length && <div className="w-full h-full flex flex-col items-center justify-center">
                <div className="gap-y-4 flex flex-col">
                    <p>{t('title.no_orders_yet')}</p>
                    <Button title={t('button.catalog')} onClick={() => navigate('/')}/>
                </div>
            </div>
        }
        {
            orders.length > 0 && <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 p-4">
                {
                    orders.map((order) => (
                        <div className="p-4 border rounded-2xl shadow-sm bg-white gap-y-2 flex flex-col justify-between">
                            <div className="gap-y-2 flex flex-col">
                                <p><b>#{order.id}</b></p>
                                <div>
                                    <p className="text-sm"><b>{t('label.created_at')}</b>: {formatDate(order.created_at)}
                                    </p>
                                    <p className="text-sm"><b>{t('label.delivery_date')}</b>: {formatDate(order.deliver_at)}
                                    </p>
                                </div>
                                <table className="w-full text-left">
                                    <thead>
                                    <th>Product</th>
                                    <th>Qty</th>
                                    <th>Total</th>
                                    </thead>
                                    {
                                        order.items.map((item) => (
                                            <tr className="border border-gray-300 divide-x">
                                                <td>{getProductName(item.product)}</td>
                                                <td>{item.qty}</td>
                                                <td>{item.price * item.qty} {currency}</td>
                                            </tr>
                                        ))
                                    }
                                </table>
                            </div>
                            <p><span className="text-primary">{t('label.total')}</span>: {order.total_amount} {currency}</p>
                        </div>
                    ))
                }
            </div>
        }
    </StoreLayout>
}

export default OrdersPage