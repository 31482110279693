import {FC, ReactNode, useState} from "react";
import {Cart, CatalogSidebar} from "./components";
import Header from "./components/Header.tsx";
import {ToastContainer} from "react-toastify";
import {AiOutlineCloseCircle} from "react-icons/ai";
import Modal from "react-modal";
import { FaCartShopping } from "react-icons/fa6";

const StoreLayout: FC<{children: ReactNode}> = ({ children }) => {
    const [mobileCartOpen, setMobileCartOpen] = useState(false)

    return <div className="flex flex-row h-screen w-screen bg-gray-100 text-base">
        <div className="w-1/6 h-full hidden lg:flex">
            <CatalogSidebar />
        </div>
        <div className="w-full lg:w-5/6 h-screen flex flex-col">
            <Header />
            <div className="w-full h-full flex flex-row overflow-y-hidden">
                <div className="w-full lg:w-4/6">
                    {children}
                </div>
                <div className="lg:w-2/6 hidden lg:flex">
                    <Cart />
                </div>
            </div>
        </div>
        <div className="lg:hidden p-4 rounded-full bg-gray-300 absolute bottom-2 right-2 text-white" onClick={() => setMobileCartOpen(true)}>
            <FaCartShopping size={20} />
        </div>

        <Modal
            isOpen={mobileCartOpen}
            onAfterOpen={() => setMobileCartOpen(true)}
            onRequestClose={() => setMobileCartOpen(false)}
            style={{content: {
                    padding: '0'
                },
            }}
        >
            <div className="flex flex-row items-end justify-end pb-2">
                <div className="cursor-pointer">
                    <AiOutlineCloseCircle size={20} onClick={() => setMobileCartOpen(false)}/>
                </div>
            </div>
            <div className="flex">
                <Cart rounded={false} />
            </div>
        </Modal>

        <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
        />
    </div>
}

export default StoreLayout