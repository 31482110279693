import { Navigate } from "react-router-dom";
import {useAuth} from "../../providers/AuthProvider.tsx";
import {FC, ReactNode} from "react";

interface Props {
    children: ReactNode;
}

export const ProtectedRoute: FC<Props> = (props) => {
    const {children} = props
    const { user } = useAuth();

    if (!user) {
        return <Navigate to="/login" />;
    }

    return children;
};