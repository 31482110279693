import {FC, useState} from "react";
import Select, {SingleValue} from "react-select";
import {useTranslation} from "react-i18next";
import {useAuth} from "../../providers/AuthProvider.tsx";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/redux.ts";
import {City} from "../../types/City.tsx";
import {setCurrentCity} from "../../redux/slices/citySlice.ts";
import AccountModal from "../../components/modals/AccountModal.tsx";
import { RxHamburgerMenu } from "react-icons/rx";
import {useCatalog} from "../../contexts/CatalogContext.tsx";
import {IoCallOutline} from "react-icons/io5";
import {filterProducts} from "../../redux/slices/productsSlice.ts";

const Header: FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { user } = useAuth();
    const [query, setQuery] = useState('');
    const { items, currentCity } = useAppSelector((state) => state.cities);
    const [accountModalOpen, setAccountModalOpen] = useState(false)
    const [burgerOpen, setBurgerOpen] = useState(false)

    const cityOptions = items.map((city) => ({
        value: city.id,
        label: city.name,
        city,
    }));

    const defaultCityValue = cityOptions.find((option) => option.city.id === currentCity?.id);

    const handleCityChange = (selectedOption: SingleValue<{ value: number; label: string; city: City }>) => {
        if (selectedOption) {
            dispatch(setCurrentCity(selectedOption.city))
        }
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setQuery(value);
        dispatch(filterProducts({ query: value }));
    };

    const languages: { value: string; label: string }[] = [
        { value: "en", label: "English" },
        { value: "pl", label: "Polski" },
        { value: "ru", label: "Русский" },
    ];

    const handleLanguageChange = (selectedOption: SingleValue<{ value: string; label: string }>) => {
        if (selectedOption) {
            i18n.changeLanguage(selectedOption.value);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/login");
    };

    if (!cityOptions.length || !currentCity) {
        return <header className="flex flex-row py-2 px-4 bg-white rounded-b-2xl items-center gap-x-2 justify-end"></header>;
    }

    return (
        <header
            className="flex flex-row py-2 px-4 bg-white rounded-b-2xl items-center gap-x-2 justify-between">
            <div className="lg:hidden">
                <div className="lg:hidden" onClick={() => setBurgerOpen(!burgerOpen)}>
                    <RxHamburgerMenu/>
                </div>
            </div>
            <div>
                <input
                    type="text"
                    placeholder={t('label.search_product')}
                    value={query}
                    onChange={handleSearch}
                    className="flex px-2 py-1 rounded-md border border-gray-300 text-base"
                />
            </div>
            <div className="hidden lg:flex flex-row gap-x-2 justify-end items-center">
                <div>
                    <Select
                        options={cityOptions}
                        defaultValue={defaultCityValue}
                        onChange={handleCityChange}
                    />
                </div>
                <div>
                    <Select
                        options={languages}
                        defaultValue={languages.find((opt) => opt.value === i18n.language)}
                        onChange={handleLanguageChange}
                    />
                </div>
                <div className="rounded-lg group">
                    <div className="relative">
                    <span className="cursor-pointer px-4 py-2 border rounded transition border-gray-300">
                        {user?.email}
                    </span>
                        <div
                            className="absolute right-0 mt-2 w-full bg-white shadow-lg rounded border hidden group-hover:block">
                            <ul>
                                <li>
                                    <p className="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                                       onClick={() => setAccountModalOpen(true)}>
                                        {t("header.account")}
                                    </p>
                                </li>
                                <li>
                                    <p
                                        className="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                                        onClick={() => navigate("/orders")}
                                    >
                                        {t("header.orders")}
                                    </p>
                                </li>
                                <li>
                                    <p
                                        onClick={handleLogout}
                                        className="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                                    >
                                        {t("header.logout")}
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {burgerOpen && <BurgerMenu close={() => setBurgerOpen(false)}/>}
            <AccountModal isOpen={accountModalOpen} close={() => setAccountModalOpen(false)}/>
        </header>
    );
};

const BurgerMenu: FC<{ close: Function }> = ({close}) => {
    const categories = useAppSelector((state) => state.categories.items);
    const {selectedCategory, setSelectedCategory} = useCatalog();
    const navigate = useNavigate();
    // @ts-ignore
    const telephone = JSON.parse(window.settings).telephone;

    const handleCategoryClick = (categoryId: number) => {
        setSelectedCategory(categoryId)
        if (location.pathname != '/') navigate(`/`);
        close()
    }

    return <div id="menu"
                className="absolute top-12 left-0 w-full bg-white shadow-md md:block md:relative md:shadow-none">
        <div className="flex flex-col w-full justify-center overscroll-y-auto divide-y divide-gray-200 h-fit">
            {categories.map((category) => (
                <div key={category.id}
                     className={`flex flex-row w-full items-center px-2 py-2 hover:bg-primary hover:text-white cursor-pointer rounded-2xl ${selectedCategory == category.id && 'bg-primary text-white'}`}
                     onClick={() => handleCategoryClick(category.id)}
                >
                    <h2>{category.name}</h2>
                    <p>{category.description}</p>
                </div>
            ))}
        </div>

        <div className="px-4 py-2 rounded-lg border border-gray-300 mt-5 mb-2 mx-2">
            <a href={`tel:${telephone}`} className="flex flex-row items-center gap-x-2">
                <IoCallOutline size={16}/> {telephone}</a>
        </div>
    </div>
}

export default Header