import {createContext, FC, ReactNode, useContext, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {useLocalStorage} from "../hooks/useLocalStorage";
import {User} from "../types/User";
import {loginUri, meUri} from "../constants/routes";
import {addNotification} from "./NotificationProvider.ts";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

interface AuthContextValue {
    user: User | null,
    login: (data: AuthData) => Promise<void>,
    register: (data: AuthData) => Promise<void>,
    logout: () => void,
    me: (authToken?: string) => Promise<boolean>
}

interface AuthProviderProps {
    children: ReactNode;
}

const AuthContext = createContext<AuthContextValue>({
    user: null,
    login: () => Promise.resolve(),
    register: () => Promise.resolve(),
    logout: () => {},
    me: (authToken?: string) => Promise.resolve(!!authToken),
});

interface AuthData {
    email: string,
    password: string
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
    const {children} = props;
    const { t } = useTranslation();
    const [user, setUser] = useLocalStorage("user", null);
    const [token, setToken] = useLocalStorage("token", null);
    const navigate = useNavigate();

    const login = async (data: AuthData) => {
        try {
            const response = await axios.post(loginUri, data)
            if (response?.data?.access) {
                setToken(response?.data?.access);
                const isAuth = await me(response?.data?.access);
                if (isAuth) {
                    navigate("/");
                } else {
                    toast.error(t('error.message.no_user_found'))
                }
            } else {
                toast.error(t('error.message.no_user_found'))
            }
        } catch (err) {
            console.log(err)
            toast.error(t('error.message.no_user_found'))
        }
    };

    const register = async (data: AuthData) => {
        try {
            const response = await axios.post('register/', data)
            if (response?.data) {
                navigate("/auth/need_confirmation");
            } else {
                addNotification('Error', 'Login error', 'danger')
            }
        } catch (err) {
            console.log(err)
            addNotification('Error', 'Login error', 'danger')
        }
    };

    const me = async (authToken?: string) => {
        if (!authToken) authToken = token

        try {
            const response = await axios.get(meUri, {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            })

            if (response?.data) {
                setUser(response?.data);
                return true
            }
        } catch (err) {}

        localStorage.removeItem('token')
        localStorage.removeItem('user')
        return false
    }

    const logout = () => {
        setUser(null);
        navigate("/", { replace: true });
    };

    const value: AuthContextValue = useMemo(
        () => ({
            user,
            login,
            logout,
            me,
            register
        }),
        [user]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = (): AuthContextValue => {
    return useContext(AuthContext);
};