import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translation/en"
import pl from "./translation/pl";
import ru from "./translation/ru";

export const languages = [
    {value: 'pl', label: "Polski"},
    {value: 'en', label: "English"},
    {value: 'ru', label: "Русский"}
];

const resources = {
    en: { translation: en },
    pl: { translation: pl },
    ru: { translation: ru },
};

const defaultLang = 'pl'
const savedLanguage = localStorage.getItem("i18nextLng") || defaultLang;

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: savedLanguage,
        interpolation: {
            escapeValue: false,
        },
    });

i18n.on("languageChanged", (lng) => {
    localStorage.setItem("i18nextLng", lng);
});

export default i18n;