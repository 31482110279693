import {useNavigate} from "react-router-dom";
import LogoSVG from "../../assets/logo.png"

const Logo = () => {
    const navigate = useNavigate()

    return <div className="max-w-52 mx-auto">
        <a href="" onClick={() => navigate('/')}>
            <img src={LogoSVG} alt="logo"/>
        </a>
    </div>
}

export default Logo