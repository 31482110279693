import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { CategoriesState, Category } from '../../types/Catalog';
import { fetchCategories } from '../../services/apiService';

const initialState: CategoriesState = {
    items: [],
    status: 'idle',
    error: null,
};

export const loadCategories = createAsyncThunk('categories/loadCategories', async () => {
    const response = await fetchCategories();
    return response as Category[];
});

const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadCategories.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadCategories.fulfilled, (state, action: PayloadAction<Category[]>) => {
                state.status = 'succeeded';
                state.items = action.payload;
            })
            .addCase(loadCategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || null;
            });
    },
});

export default categoriesSlice.reducer;